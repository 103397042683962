import React, { useState } from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import { Fv } from "components/fv"
import * as FadeIn from "components/fadein"

const LaundryoutPage = () => {
  const [openStandard, setOpenStandard] = useState(false)
  const [openNatural, setOpenNatural] = useState(false)
  const [openDelicate, setOpenDelicate] = useState(false)
  const hundleOnToggle = (fn, state) => {
    fn(!state)
  }
  return (
    <Layout>
      <div className="laundry-out-page design-style">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/laundry-service/laundry-out/mv-img.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/laundry-service/laundry-out/sp-mv-img.jpg"
          fvTitle="Wash, Dry + Fold"
          color="white"
        />
        <FadeIn.Up>
          <div className="lead-area">
            <h2 className="title tx-center">
              洗濯のプロが提供する
              <br />
              ハイクオリティの衣類ケア
            </h2>
          </div>
        </FadeIn.Up>
        <FadeIn.Up>
          <div className="main-contents">
            <div className="title-wrap">
              <div className="lead-wrap">
                <p>
                  日常の洗濯物を洗濯・乾燥して、1点1点きれいにたたんでお戻しする洗濯サービスです。
                  <br className="pc" />
                  洗濯物やお好みに合わせて複数のコースから選べます。
                  <br className="pc" />
                  忙しく時間がない方・洗濯嫌いの方・衣類をずっと大切に着たい方にもオススメです。
                </p>
              </div>
            </div>
            <div className="content detergent">
              <h4 className="title">
                <span className="e">Detergent / Softener</span>
                <span className="j">洗剤/柔軟剤</span>
              </h4>
              <div className="lead-wrap">
                <div className="about-course">
                  <div
                    className={`${
                      openStandard ? "open-info" : ""
                    } course-title standard`}
                    onClick={() =>
                      hundleOnToggle(setOpenStandard, openStandard)
                    }
                    onKeyDown={() =>
                      hundleOnToggle(setOpenStandard, openStandard)
                    }
                    role="button"
                    tabIndex={0}
                  >
                    〔スタンダードコース〕
                  </div>
                  <div className="course-details standard">
                    <p className="lead">
                      肌に優しく<small>※1</small>、衣類に優しく
                      <small>※２</small>
                      、環境に優しいこと<small>※３</small>
                      を最大の目的としつつ、洗浄力も兼ね備えた自社開発の洗剤を使用。天然のアロマオイルを使用した格調高い香りの柔軟剤も自社開発。洗濯後の衣類からは、ラベンダーとユーカリの香りがほのかに漂います。
                    </p>
                    <div className="image">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/laundry-service/laundry-out/course-img-standard.jpg"
                        height="auto"
                        width={380}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="note">
                      ※１肌の成分(酸性)によせた、中性域で製造。
                      ※２繊維を傷めないとされる中性域で製造
                      ※３LAS、リン不使用。PRTR法非該当。生分解性の高い、ヤシ油由来のノニオン界面活性剤使用。
                    </p>
                  </div>
                  <div
                    className={`${
                      openNatural ? "open-info" : ""
                    } course-title natural`}
                    onClick={() => hundleOnToggle(setOpenNatural, openNatural)}
                    onKeyDown={() =>
                      hundleOnToggle(setOpenNatural, openNatural)
                    }
                    role="button"
                    tabIndex={0}
                  >
                    〔ナチュラルコース〕
                  </div>
                  <div className="course-details natural">
                    <p className="lead">
                      大正創業の老舗石鹸メーカーの洗剤を使用したコースです。石鹸ならではの泡立ちと泡切れの良さが特徴。肌や衣類、環境への優しさを大切にしたい方におすすめです。天然のオレンジ精油の香りで、衣類も気分もリフレッシュ。
                    </p>
                    <div className="image">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/laundry-service/laundry-out/course-img-natural.jpg"
                        height="auto"
                        width={380}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="note">
                      不使用成分
                      <br />
                      【石油由来界面活性剤】【合成香料】【合成着色料】【石油由来防腐剤】【旧表示指定成分】
                    </p>
                  </div>
                  <div
                    className={`${
                      openDelicate ? "open-info" : ""
                    } course-title delicate`}
                    onClick={() =>
                      hundleOnToggle(setOpenDelicate, openDelicate)
                    }
                    onKeyDown={() =>
                      hundleOnToggle(setOpenDelicate, openDelicate)
                    }
                    role="button"
                    tabIndex={0}
                  >
                    〔デリケートコース〕
                  </div>
                  <div className="course-details delicate">
                    <p className="lead">
                      洗濯物へのダメージが少ない、デリケートな衣類の洗濯に適した専用洗剤を使用。洗濯物を優しく洗い上げます。洗濯乾燥の事前準備として、3
                      つの工程で衣類のダメージを減らします。
                    </p>
                    <div className="image">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/laundry-service/laundry-out/course-img-natural.jpg"
                        height="auto"
                        width={380}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div className="point">
                      <ol>
                        <li>
                          <span className="no">❶</span>{" "}
                          衣類を１点１点裏返して摩擦を防止
                        </li>
                        <li>
                          <span className="no">❷</span>{" "}
                          ネットを使用し型崩れを防止
                        </li>
                        <li>
                          <span className="no">❸</span>{" "}
                          色物や柄物は白物と分けて色写りを防止
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <h4 className="title">
                <span className="e">Wash</span>
                <span className="j">洗濯機</span>
              </h4>
              <div className="lead-wrap">
                <p>
                  最新型のドラム式洗濯機を使用し、しっかりと洗浄。
                  <br />
                  衣類を大切に考えた独自のコース設定で、
                  <br className="sp" />
                  洗濯、すすぎ、脱水をしっかり行います。
                </p>
              </div>
            </div>
            <div className="content">
              <h4 className="title">
                <span className="e">Dry</span>
                <span className="j">乾燥</span>
              </h4>
              <div className="lead-wrap">
                <p>
                  ドラム式のガス乾燥機で、繊維を根元からしっかりと
                  <br className="sp" />
                  立ち上げながら乾燥させます。
                  <br className="pc" />
                  衣類に合わせた温度設定で、
                  <br className="sp" />
                  洗濯後の衣類をシワなくふかふかに仕上げます。
                </p>
              </div>
            </div>
            <div className="content fold">
              <h4 className="title">
                <span className="e">Fold</span>
                <span className="j">たたみ</span>
              </h4>
              <div className="lead-wrap">
                <p>
                  衣類を1枚1枚美しく丁寧に、サイズを合わせてたたみます。
                  <br />
                  たたみ終えた洗濯物はパッキングしてお返し。
                  <br className="sp" />
                  そのまま収納していただくことが可能です。
                </p>
              </div>
            </div>
          </div>
        </FadeIn.Up>

        <FadeIn.Up>
          <div className="photos">
            <img
              className="lazy pc"
              src="https://balukoweb.blob.core.windows.net/images/laundry-service/laundry-out/img-01.jpg"
              width="100%"
              height="auto"
              alt=""
              loading="lazy"
            />
            <img
              className="lazy sp"
              src="https://balukoweb.blob.core.windows.net/images/laundry-service/laundry-out/sp-img-01.jpg"
              width="100%"
              height="auto"
              alt=""
              loading="lazy"
            />
          </div>
        </FadeIn.Up>

        <FadeIn.Up>
          <div className="under-contents tx-center">
            <div className="lead-wrap">
              <p className="pr-txt">
                Baluko Laundry Placeが
                <br />
                プロデュースするウェブサービス
              </p>
            </div>
            <a
              className="logo-link"
              target="_blank"
              href="https://laundry-out.jp/?utm_source=baluko.jp&utm_medium=laundryout&utm_content=footerbtn"
              style={{ display: "inline-block" }}
              rel="noopener noreferrer"
            >
              <div>
                <img
                  className="lazy lo-logo-black"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/laundry-out/lo-logo-black.png"
                  width={202}
                  height="auto"
                  alt=""
                  loading="lazy"
                />
              </div>
              <span className="lead-wire link-style e fw-b">
                ご利用はこちらから
              </span>
            </a>
          </div>
        </FadeIn.Up>
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="洗濯代行 ー 日常のお洗濯をお客様に代わって私たちがケアいたします。"
    description="日常の洗濯物を洗濯・乾燥して、1点1点きれいにたたんでお戻しする洗濯サービスです。お申し込みは、WEBまたは取扱店舗にて。"
  />
)
export default LaundryoutPage
